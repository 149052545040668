import { axiosInstance as axios } from '@/query/axios';

export const getTradeIn = async (params: TradeIn.IGetTradeInReq) => {
  try {
    const response = await axios.get(`/user_api/trade-in`, { params, needsAuth: true });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const RequestTradeInGuest = async (params: TradeIn.IRequestTradeInGuestReq) => {
  try {
    const response = await axios.post(`/user_api/trade-in/guest`, { ...params });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const RequestTradeIn = async (params: TradeIn.IRequestTradeInReq) => {
  try {
    const response = await axios.post(`/user_api/trade-in`, { ...params }, { needsAuth: true });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const cancelTradeIn = async (params: TradeIn.ICancelTradeInReq) => {
  try {
    const response = await axios.put(`/user_api/trade-in/${params.tradeInId}`, null, { needsAuth: true });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};
