import { css } from '@emotion/react';
import { colors, flex, font, layout } from 'styles/mixin';

const dealershipInfoModalStyle = css`
  &.modal-dealership-info-inner.modal-wrap-class.modal-inner {
    height: auto;
    max-height: 82vh;

    .modal-middle {
      padding-bottom: 4rem;
    }

    .modal-top {
      justify-content: center;
      padding: 4rem 4.2rem 3.6rem 4.2rem;
    }

    .dealer-block {
      .info-box {
        .info {
          ${font('1.6rem', 400, colors.$color_767, '2.4rem')};
        }
      }
    }
  }

  .dealership-info-container {
    margin-top: 4rem;

    .dealership-info-title-box {
      ${flex('row', 'space-between', 'center')};
      margin-bottom: 2rem;
      .title-box {
        ${font('1.8rem', 500, colors.$color_000, '2.4rem')};
      }
    }
    .dealer-block {
      .info-box {
        .info {
          ${font('1.6rem', 400, colors.$color_767, '2.4rem')};
        }
      }
    }
  }

  @media ${layout.$mobile} {
    &.modal-dealership-info-inner.modal-wrap-class {
      &.modal-inner {
        .modal-area {
          width: 100%;
        }
        .modal-top {
          ${flex('row', 'space-between', 'center')};
          padding: 1.6rem 2rem;
          border-bottom: 1px solid ${colors.$color_ede};
          .btn-close {
            position: static;
            transform: none;
          }
        }
        .modal-middle {
          padding: 1.6rem 2rem 3rem;
        }
      }
      .dealership-info-block-container {
        border: 0;
        .dealership-info-block-area {
          padding: 0;
        }

        .dealer-block {
          .info-box {
            .info {
              font-size: 1.3rem;
              line-height: 2rem;
            }
          }
        }
      }
    }
  }
  // }
`;

export default dealershipInfoModalStyle;
