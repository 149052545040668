import { css } from '@emotion/react';
import { colors, flex, font, layout } from 'styles/mixin';

const dealershipInfoBlockStyle = css`
  &.dealership-info-block-container {
    border: 1px solid ${colors.$color_ddd};

    .dealership-info-block-area {
      display: flex;
      padding: 2.4rem 0;
      > div {
        flex: 1;
      }
      .dealer-block-box {
        height: 100%;
      }
    }

    .dealer-block-button {
      border: 0px;
      :hover {
        cursor: default;
      }
    }

    .dealer-block {
      width: auto;
      height: 100%;
      padding: 0 2.4rem;

      // TODO: THAIONSALE-1954 DOTY 이미지 추가 건 - my Garage 견적서 딜러 정보만 스타일링 추가
      display: flex;
      flex-direction: column;

      .title {
        font-weight: 400;
      }
      .dealer-location-box {
        display: none;
      }
      .dealer-block-bottom {
        height: 100%;
        margin-top: 1.2rem;
      }
    }
    .line-button-box {
      width: 100%;
      button {
        width: 100%;
        margin-top: 1.6rem;
      }
    }

    .dealership-info-bottom-right {
      .dealer-block-box {
        border-left: 1px dashed ${colors.$color_ddd};
      }
    }
  }

  @media ${layout.$mobile} {
    &.dealership-info-block-container {
      .dealership-info-block-area {
        display: block;
      }

      .dealership-info-bottom-right {
        margin-top: 1.6rem;
        padding-top: 1.6rem;
        border-top: 1px dashed ${colors.$color_999};
        .dealer-block-box {
          border-left: 0;
        }
      }

      .dealer-block {
        padding: 0;
        .title {
          font-size: 1.5rem;
          line-height: 2.2rem;
        }

        .dealer-block-bottom {
          margin-top: 0.8rem;
        }

        .info-box {
          .info {
            font-size: 1.3rem;
            line-height: 2rem;
          }
        }
      }
    }
  }
`;

export default dealershipInfoBlockStyle;
