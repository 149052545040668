import { axiosInstance as axios } from '@/query/axios';

export const contactUs = async (params: ContactUs.IRequestContactUsReq) => {
  try {
    const response = await axios.post(`/user_api/contact-us`, { ...params }, { needsAuth: true });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const contactUsGuest = async (params: ContactUs.IRequestContactUsGuestReq) => {
  try {
    const response = await axios.post(`/user_api/contact-us/guest`, { ...params });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const cancelContactUs = async (params: ContactUs.ICancelContactUsReq) => {
  try {
    const response = await axios.put(`/user_api/contact-us/${params.contactUsId}`, null, { needsAuth: true });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};
