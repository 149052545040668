import Modal from '@/components/modules/modals/modal';
import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'next-i18next';
import dealershipInfoModalStyle from './style';
import DealershipBlock from '@/components/modules/dealershipBlock';

const DealershipInfoModal = forwardRef<TDealershipInfoModalHandle, IDealershipInfoModalProps>(function (
  {
    hasChange,
    dealerTitle,
    consultantTitle,
    distance,
    location,
    dealerCall,
    time,
    name,
    consultantCall,
    email,
    lineId,
    hasConsultant,
    lat,
    lng,
    ...props
  },
  ref,
) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    openDealershipInfoModal: () => {
      setIsOpen(true);
    },
  }));

  return (
    <Modal
      size={'large-popup'}
      backDropClose={false}
      displayNoneFooter={false}
      id="dealership-info"
      modalWrapperClassName={classNames('large-popup', 'modal-quick-menu', 'modal-dealership-info')}
      className={classNames('modal-dealership-info-inner')}
      role="dialog"
      aria-modal="true"
      aria-hidden="true"
      aria-labelledby="Dealership Info"
      aria-describedby="Dealership Information"
      css={dealershipInfoModalStyle}
      visible={isOpen}
      closeModal={() => setIsOpen(false)}
      modalTitle={
        <div className="modal-top-left">
          <div className="modal-title-box">
            <p className="modal-title modal-title-class">{t('Label.DealershipInfo')}</p>
          </div>
        </div>
      }
      {...props}
    >
      <DealershipBlock
        dealerTitle={dealerTitle}
        consultantTitle={consultantTitle}
        distance={distance}
        location={location}
        dealerCall={dealerCall}
        time={time}
        name={name}
        consultantCall={consultantCall}
        email={email}
        lineId={lineId}
        lng={lng}
        lat={lat}
        hasConsultant={hasConsultant}
      />
    </Modal>
  );
});

DealershipInfoModal.displayName = 'DealershipInfoModal';

export default DealershipInfoModal;
