import { useMutation } from '@tanstack/react-query';
import { cancelTradeIn, RequestTradeIn, RequestTradeInGuest } from './api';
import { TUseMutationOptions } from '../type';

export function useRequestTradeIn(options?: TUseMutationOptions<unknown, TradeIn.IRequestTradeInReq>) {
  return useMutation((params: TradeIn.IRequestTradeInReq) => RequestTradeIn(params), { ...options });
}

export function useRequestTradeInGuest(options?: TUseMutationOptions<unknown, TradeIn.IRequestTradeInGuestReq>) {
  return useMutation((params: TradeIn.IRequestTradeInGuestReq) => RequestTradeInGuest(params), { ...options });
}

export const useRequestTradeInCancel = (
  options?: TUseMutationOptions<TradeIn.ICancelTradeInRes, TradeIn.ICancelTradeInReq>,
) => {
  return useMutation((params: TradeIn.ICancelTradeInReq) => cancelTradeIn(params), { ...options });
};
