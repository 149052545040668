import { useMutation } from '@tanstack/react-query';
import { cancelContactUs, contactUs, contactUsGuest } from './api';
import type { TUseMutationOptions } from '../type';

export function useContactUs(options?: TUseMutationOptions<unknown, ContactUs.IRequestContactUsReq>) {
  return useMutation((params: ContactUs.IRequestContactUsReq) => contactUs(params), { ...options });
}

export function useContactUsGuest(options?: TUseMutationOptions<unknown, ContactUs.IRequestContactUsGuestReq>) {
  return useMutation((params: ContactUs.IRequestContactUsGuestReq) => contactUsGuest(params), { ...options });
}
export const useRequestContactUsCancel = (options?: TUseMutationOptions<unknown, ContactUs.ICancelContactUsReq>) => {
  return useMutation((params: ContactUs.ICancelContactUsReq) => cancelContactUs(params), {
    ...options,
  });
};
