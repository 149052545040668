import React from 'react';
import DealerBlock from '@/components/modules/dealerBlock';
import classNames from 'classnames';
import dealershipInfoBlockStyle from './style';
import ConsultantBlock from './components/consultantBlock';

function DealershipBlock({
  // 딜러 정보
  dealerTitle,
  consultantTitle,
  hasChange = false,
  distance,
  location,
  dealerCall,
  // SC 정보
  consultantCall,
  name,
  email,
  time,
  dealerId,
  className,
  defaultEmployeeId,
  lineId,
  handleChange,
  hasConsultant = true,
  readyToDelivery,
  lat,
  lng,
  ...otherProps
}: IDealerShipBlockProps) {
  return (
    <div
      className={classNames('dealership-info-block-container', className)}
      css={dealershipInfoBlockStyle}
      {...otherProps}
    >
      <div className="dealership-info-block-area">
        <div className="dealership-info-bottom-left">
          <div className="dealer-block-box">
            <DealerBlock
              title={dealerTitle}
              distance={distance}
              location={location}
              call={dealerCall}
              time={time}
              readyToDelivery={readyToDelivery}
              lat={lat}
              lng={lng}
              // TODO: THAIONSALE-1954 DOTY 이미지 추가 건 - my Garage 견적서 딜러 정보에서만 name으로 비교 (qutation 데이터에서 dealerCode가 없음)
            />
          </div>
        </div>
        {hasConsultant && (
          <div className="dealership-info-bottom-right">
            <div className="dealer-block-box">
              <ConsultantBlock
                dealerId={dealerId}
                name={name}
                title={consultantTitle}
                call={consultantCall}
                email={email}
                hasChange={hasChange}
                defaultEmployeeId={defaultEmployeeId}
                handleChange={handleChange}
                lineId={lineId}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DealershipBlock;
