import { useMutation, useQuery } from '@tanstack/react-query';
import { getTestDriveModel, RequestTestDrive, RequestTestDriveGuest, cancelTestDrive } from './api';
import type { TUseMutationOptions } from '../type';

export function useRequestTestDrive(options?: TUseMutationOptions<unknown, TestDrive.IRequestTestDriveReq>) {
  return useMutation((params: TestDrive.IRequestTestDriveReq) => RequestTestDrive(params), {
    ...options,
    useErrorBoundary: false,
  });
}

export function useRequestTestDriveGuest(options?: TUseMutationOptions<unknown, TestDrive.IRequestTestDriveGuestReq>) {
  return useMutation((params: TestDrive.IRequestTestDriveGuestReq) => RequestTestDriveGuest(params), { ...options });
}

export function useTestDriveModel(params: TestDrive.IGetTestDriveModelReq) {
  return useQuery<TestDrive.TGetTestDriveModelRes, Error>(['TEST_DRIVE_MODEL'], () => getTestDriveModel(params), {
    enabled: !!params?.dealerId,
  });
}
export const useRequestTestDriveCancel = (options?: TUseMutationOptions<unknown, TestDrive.ICancelTestDriveReq>) => {
  return useMutation((params: TestDrive.ICancelTestDriveReq) => cancelTestDrive(params), { ...options });
};
