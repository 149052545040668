import { axiosInstance as axios } from '@/query/axios';

export const getTestDriveModel = async (
  params: TestDrive.IGetTestDriveModelReq,
): Promise<TestDrive.TGetTestDriveModelRes> => {
  try {
    const response = await axios.get(`/user_api/testdrive`, { params });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const getTestDrive = async (params: TestDrive.IGetTestDriveReq) => {
  try {
    const response = await axios.get(`/user_api/test-drive`, { params, needsAuth: true });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const RequestTestDriveGuest = async (params: TestDrive.IRequestTestDriveGuestReq) => {
  try {
    const response = await axios.post(`/user_api/test-drive/guest`, { ...params });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const RequestTestDrive = async (params: TestDrive.IRequestTestDriveReq) => {
  try {
    const response = await axios.post(`/user_api/test-drive`, { ...params }, { needsAuth: true });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const cancelTestDrive = async (params: TestDrive.ICancelTestDriveReq) => {
  try {
    const response = await axios.put(`/user_api/test-drive/${params?.id}`, null, { needsAuth: true });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};
